import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "../link";
import { GatsbyImage } from "gatsby-plugin-image";

const PhotoLeftLong = ({
	heading,
	text,
	text2,
	text3,
	text4,
	text5,
	img,
	cta,
	ctaUrl,
}) => {
	return (
		<section className=" my-5">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} lg={6} className="mb-4 mb-md-0">
						<GatsbyImage image={img} />
					</Col>
					<Col xs={12} lg={6}>
						<h2 className="my-4 mb-md-0 text-start text-lg-center">
							{heading}
						</h2>
						<p>{text}</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<p>{text2}</p>
						<p>{text3}</p>
						<p>{text4}</p>
						<p>{text5}</p>
						<Link to={ctaUrl}>
							<p className="text-primary fw-bold cta-link">{cta}</p>
						</Link>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PhotoLeftLong;
