import React from "react";
import CtaBanner from "../../components/cta-banner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Link from "../../components/link";
import ServicesHero from "../../components/services/hero";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import PhotoRightLong from "../../components/services/photo-right-long";
import PhotoLeftLong from "../../components/services/photo-left-long";
import Review from "../../components/review";
import Montage from "../../components/services/montage";
import Faq from "../../components/services/faq";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const ProductPhotographyPage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			productImg: file(relativePath: { eq: "services/product.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			skinImg: file(relativePath: { eq: "services/product/skin.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			tonicImg: file(relativePath: { eq: "services/product/tonic.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			tonicImg2: file(relativePath: { eq: "services/product/tonic.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			accountingImg: file(
				relativePath: { eq: "services/product/accounting.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			handbagImg: file(relativePath: { eq: "services/product/handbag.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			handbagImg2: file(relativePath: { eq: "services/product/handbag.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bagImg: file(relativePath: { eq: "services/product/bag.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			dnaImg: file(relativePath: { eq: "services/product/dna.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			lampshadeImg: file(
				relativePath: { eq: "services/product/lampshade.jpg" }
			) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			sweetsImg: file(relativePath: { eq: "services/product/sweets.png" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			ginImg: file(relativePath: { eq: "services/product/gin.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bathroomImg: file(relativePath: { eq: "services/product/bathroom.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			flowersImg: file(relativePath: { eq: "services/product/flowers.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/product-photography";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.productImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Product Photographer",
				item: `${siteUrl}/services/product-photography`,
			},
		],
	};
	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#product-photographer`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"I specialise in all forms of product photography including lifestyle photography, shots for eCommerce and packshot photography. See my portfolio for details.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/reviews`,
			datePublished: "2021-05-21T17:58:29+00:00",
			reviewBody:
				"Sarah is my go to person for any branding photography, headshots, e commerce product shots and so much more. Sarah is passionate about her craft and this shines through in her wonderful photography. Sarah is quick to respond to queries and very reasonable cost wise. Highly recommended",
			author: {
				"@type": "Person",
				name: "Jo Hodgson",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},

			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};

	const faqs = [
		{
			question: "What type of products can you photograph?",
			answer:
				"I have experience photographing products from a range of industries. From water valves for an engineering company to food products in their packaging. Check out my portfolio for others I have worked with. If you have a product that you sell online, I can shoot for e-commerce on a white background. These are always shot in studio to ensure the light is controlled and you achieve the correct look required. If you need a product to be shot in a lifestyle image, this is also achieved either in my studio, or on location.",
		},
		{
			question: "How long will it take to shoot my products?",
			answer:
				"This is a question that is hard to answer. If you have products that need to be all shot on a white background in the same fashion, then once I’ve set the lighting up it’s just a case of placing each product in front of the camera and ensuring that each one looks perfect then shooting. However, if you have a number of different requirements for each product and want props as well, this will take longer to ensure the images are pleasing to the eye and the props work well. Then there is a lot of tweaking after each shot to make sure I’m happy so that you’ll be happy. Normally a week would be a guide to shoot, edit and deliver the finished images for 10 products.",
		},
		{
			question: "How do I get my products to you?",
			answer:
				"Depending on where you’re based in relation to me, you can drop them off to me. During COVID we can do this safely at a distance. If not, then by courier would be the best and safest way to deliver to me. If they are non-perishing goods, so not food, I will return them via courier once I’ve completed the shoot and you’ve approved the images.",
		},
		{
			question:
				"Do you work on a retainer basis if I have the need for ongoing product photography?",
			answer:
				"Yes, I work with a number of my clients this way. We agree a number of products a month to shoot, agree a price and then we set up a Go-Cardless direct debit to run each month. It is your responsibility to get the products to me each month.",
		},
	];

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Product Photographer in Sussex | Sarah Bennett"
				description="I specialise in all forms of product photography including lifestyle photography,  shots for eCommerce and packshot photography. See my portfolio for details."
				language="en"
				canonical="https://www.sarah-bennett.com/services/product-photography"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/product-photography",
					title:
						"Product Photographer East & West Sussex | Sarah Bennett Commercial Photography",
					description:
						"I specialise in all forms of product photography including lifestyle photography,  shots for eCommerce and packshot photography. See my portfolio for details.",
					images: [
						{
							url: `${data.productImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Product Photographer East & West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				subheadline=" "
				headline={["Product", <br />, "Photographer"]}
				img={data.productImg.childImageSharp.gatsbyImageData}
				showBtn={true}
			/>
			<PhotoLeft
				img={data.sweetsImg.childImageSharp.gatsbyImageData}
				heading="Product Photography Expertise"
				text={[
					"I have extensive experience of product photography shoots with a wide range of clients, from bathroom showrooms to handbags and",
					<Link to="/services/food-photography"> food photography </Link>,
					"Small products can be photographed here in my photography studio in a light-controlled environment. Equally, I can also use my mobile studio and natural lighting if the brief requires.",
				]}
			/>
			<PhotoRight
				img={data.tonicImg.childImageSharp.gatsbyImageData}
				heading="Sell more products"
				text="High-quality product photos or images are so important if you want to sell products online because your customers can’t touch and see the products for themselves. Your website and social media feeds should be full of not only quality images, but beautiful images to tempt your customers."
			/>
			<PhotoLeft
				img={data.skinImg.childImageSharp.gatsbyImageData}
				heading="Meet e-commerce guidelines"
				text="Product photography covers all kinds of e-commerce photography, advertising photography, product launch photography, manufacturing photography and photography for selling on online platforms such as, Amazon and eBay, which require a white background and have their own specific requirements and guidelines."
			/>
			<Review
				author="Ruth Weaver"
				company="Purple Cow Office Supplies"
				quote="Sarah has done an enormous amount of product photography for my office supplies company. What she produces is amazing. She represents my brand perfectly. A very talented photographer with a fabulous understanding of what her clients want and she delivers! Highly recommend!"
			/>
			<Montage
				img1={data.accountingImg.childImageSharp.gatsbyImageData}
				img2={data.handbagImg.childImageSharp.gatsbyImageData}
				img3={data.dnaImg.childImageSharp.gatsbyImageData}
				img4={data.lampshadeImg.childImageSharp.gatsbyImageData}
				img5={data.flowersImg.childImageSharp.gatsbyImageData}
			/>
			<CtaBanner
				headline="Refresh your company's image brand-new, bespoke photos that reflect where your business is now - and where you want it to go!"
				btnText="Let's Talk"
				url="/contact-us"
			/>
			<PhotoRight
				img={data.tonicImg2.childImageSharp.gatsbyImageData}
				heading="Lifestyle and Pack Shot Photography"
				text="I also offer lifestyle photography in which the backdrop tries to convey a certain persona, as well as pack shot photography. Pack shot is where  the packaging is photographed alongside the product to create awareness and recognition of the packaging in the media it is advertised in so that the product is then instantly recognisable at points of sale."
			/>
			<Review
				author="Ian Dove"
				company="Pick and Mix Sweets"
				quote="Sarah did some great photos for me and it really spruced up my website. Sarah thought about what I said I needed and the result was spot on. Couldn't be happier and would recommend."
			/>

			<section className="pt-md-6 pb-4 pt-4 pb-md-0 bg-white">
				<Container>
					<Row>
						<Col className="mb-md-3">
							<h2 className="text-start text-md-center">
								New Product Launches
							</h2>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" pt-md-4 pt-2 bg-white">
				<Container>
					<Row>
						<Col>
							<p>
								I offer comprehensive product photography for new product
								launches. Whether you're a start-up looking to release your
								first product, or you run an established company launching a new
								line, my shots can capture your product and bring it to life.
								Pictures are an extremely important part of new product
								launches. They can drastically increase sales, setting great
								expectations for the consumer. Though they may appear simple,
								capturing brilliant photographs can be extremely difficult,
								which can become stressful for both your team and the marketing
								department. Rather than stressing about the style, or creating
								your own complex portfolio, I can take care of all commercial
								product photography for you.
							</p>
							<p>
								As part of my service, I cover every aspect of your photography
								for you. This includes the initial brief, styling, photos,
								lighting and editing, all provided with great pricing. Making it
								my job to offer the best photos for businesses in Sussex, London
								or the South East, each image can be as complex or simple as you
								want it. I can include props and other items for product shots,
								additional creative aspects for marketing as well as tailored
								editing for different brands. This ensures that the final
								photographs are exactly what you're looking for.
							</p>
							<p>
								I truly believe that professional images can have a huge impact
								on brands. Commercial product photography plays a huge part in
								many of the businesses we see today, helping them establish not
								only their creative side but also their brand reputation. If
								your team are interested in professional photography for a new
								product launch in East Sussex, I'm here to help.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Contact me today and discover how we can take your products to the next level."
				btnText="Let's Talk"
				url="/contact-us"
			/>
			<PhotoLeft
				img={data.handbagImg2.childImageSharp.gatsbyImageData}
				heading="E-Commerce Shops"
				text="It is estimated that there are over 12 million e-commerce stores online. With so much competition, it's important that your product photography stands out in your industry. With my E-commerce photography service, you can take pleasure knowing that your store has amazing photography that sets your company apart. Available throughout Sussex, London and the South East, I can recommend the right background, lighting and process to ensure you get brilliant results with each shoot. This can be completed in both studio settings or outside for a more creative approach. No matter your choice or approach, you can enjoy professional product shots that align with your business."
			/>
			<PhotoRightLong
				img={data.ginImg.childImageSharp.gatsbyImageData}
				heading="Lifestyle Product Photography"
				text="As part of my e-commerce service, I offer lifestyle product photography for each of my clients. This includes photographs that use unique or interesting backgrounds to compliment and bring out the best feature in each product. These shots are amazing due to their eye-catching final result. Perfect for brands looking to stand out, they can be as simple as a unique background or can be complemented with props and additional styling features."
				text2="As well as being great for e-commerce stores, these photos are fantastic for social media, taking a post from good to great. I highly recommend them for advertising purposes, or as part of a well-rounded portfolio. My clients have seen amazing results when using them on social media, with each of the pictures being extremely popular amongst their audience. In general, the production of this photography type is completed in a studio, this way we can shoot the highest quality images."
				text3="This service can be tailored to the budget of your business, ensuring you can enjoy great results with competitive prices for your shoots. For more information about my commercial product photography, check out my website or give me a call for a free consultation."
			/>
			<PhotoLeftLong
				ctaUrl="/contact-us"
				cta="Find out more &#x25B6;"
				img={data.bagImg.childImageSharp.gatsbyImageData}
				heading="Amazon/ eBay Shops"
				text="When it comes to Amazon shops, it's common for a business to fall at the first hurdle. This is because photos are required to have a white background. As a result, a creative approach is needed to bring the pictures to life. This is where a professional photographer can help. I have an in-depth understanding of what exactly is allowed in each of your pictures, ensuring that your background is simple yet effective. I use unique lighting tricks throughout the production process to help ensure each of your pictures displays the quality of your products."
				text2="In general, a studio is required as part of this product photography service to ensure great shots that are both creative and align with your business. I will also recommend how you can get the most out of your budget, offering great prices for each job you need. Should you not want to use a studio, these images can also be taken in a house where the lighting can be controlled. In this situation, I can bring any required equipment to ensure a high-quality photograph is captured for your brand."
				text3="Professional images are a crucial part of any Amazon/ eBay store. They help to bring your product and brand to life, helping your customer understand what they're buying before a purchase."
				text4="My services are available throughout the South East, with tailored pricing for your location and requirements. No matter what you're looking for, I have the knowledge and experience to provide you with exactly what you need. Whether you need a single image for a social media post, several images for your brand, or a photographer for your team, I am here to help. I can recommend everything you need for each production and can also help you search for anything extra online. Striving for nothing less than the highest standards, you won't find another photographer in Sussex that holds themselves to such standards. I always aim for a fast turnaround, with free delivery on bulk orders. Should you need an extra fast turnaround, I will do my best to deliver."
				text5="If you're looking for a professional photography service, search no longer. Simply contact me directly, and I will let you know how my services can help you take your business to the next level. I look forward to hearing from you."
			/>
			<section className="pt-md-6 pb-4 pt-4 pb-md-0 bg-white">
				<Container>
					<Row>
						<Col className="mb-md-3">
							<h2 className="text-start text-md-center">
								Product Photography process
							</h2>
							<hr className="w-25 mx-md-auto " style={{ opacity: 1 }} />
						</Col>
					</Row>
				</Container>
			</section>
			<section className=" pt-md-4 pt-2 bg-white">
				<Container>
					<Row>
						<Col>
							<p>
								When it comes to product photography the majority are shot in my
								home studio. I have a studio that can handle products up to
								approx. 2 metres square in size.  I run a drop off service for
								products at my address. Space is limited so I’m unable to have
								clients in the studio, however, once the images have been agreed
								upon, they can be collected. Please note due to the space
								limits, I’m not able to store products for long.
							</p>
							<p>
								You can choose to have products shot on a white background which
								is used in Amazon and eBay sites and other e-commerce shops.
								Alternatively for products that require a more lifestyle feel
								props and backgrounds can be used in the images with your
								products that are appropriate and relevant.
							</p>
							<p>
								For larger products including bikes, cars, and furniture, these
								would be shot on location, either at a larger hired studio or an
								agreed outside location.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Faq faqs={faqs} />
			<CtaBanner
				headline="Book an appointment to find out how I can help you with product photography"
				btnText="Let's Talk"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default ProductPhotographyPage;
